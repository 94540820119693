<template>
  <modal :open="state.open" @close="closeModal">
    <h1>
      {{ $t('general.action.deleteSth', [te(entityTranslationKey + '.label') ? $t(entityTranslationKey + '.label') : $t('general.object.label')]) }}
    </h1>
    <p class="mb-4">
      {{ $t('general.deleteQuestion', [
        te(entityTranslationKey + '.labelWithArticle') ? $t(entityTranslationKey + '.labelWithArticle') : '',
        entity && entity[label] ? entity[label] : '',
      ]) }}
    </p>
    <p class="mb-4 font-bold">
      {{ $t('general.deleteWarning', [te(entityTranslationKey + '.label') ? $t(entityTranslationKey + '.label', 2) : '']) }}
    </p>
    <slot name="additional" />
    <form-buttons
      :loading-submit="loading"
      confirm-text="Ja, löschen"
      delete-button
      @cancel="closeModal"
      @submit="deleteEntity"
    />
  </modal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { notify } from '@kyvg/vue3-notification';
import axios from '@/services/axios';
import Modal from '@/components/utils/Modal.vue';
import FormButtons from '@/components/utils/forms/FormButtons.vue';

export default {
  name: 'DeleteModal',
  components: { FormButtons, Modal },
  props: {
    /**
     * Translation key "prefix" for showing the labels.
     * Will be used to translate the keys label and labelWithArticle, like
     * $t('entity.label') and $t('entity.labelWithArticle')
     */
    entityTranslationKey: { type: String, default: '' },
    /**
     * Property of the entity which contains the "name" of the entity,
     * eg. Object "foo" has it's name in "title", then use "title".
     * @example name
     */
    label: { type: String, default: 'name' },
    /**
     * Modal Type to be used in Vuex Modal module.
     */
    type: { type: String, required: true },
  },
  emits: ['update'],

  setup(props, { emit }) {
    const { t, te } = useI18n();
    const store = useStore();
    const entity = computed(() => store.state.modals.entity);
    const state = ref({ open: false });
    const loading = ref(false);

    const deleteEntity = async () => {
      loading.value = true;
      try {
        await axios.delete(entity.value['@id']);
        store.dispatch('modals/closeModal', props.type);
        const label = te(`${props.entityTranslationKey}.labelWithArticle`)
          ? t(`${props.entityTranslationKey}.labelWithArticle`) : t(`${props.entityTranslationKey}.label`);
        notify(`${label} wurde gelöscht.`);
        emit('update');
      } catch (err) {
        console.error(err, err.message, err.response);
        notify({
          title: 'Fehler beim Löschen',
          text: err?.response?.data?.['hydra:description'] ?? err?.response?.data ?? null,
          type: 'error',
        });
      }
      loading.value = false;
    };

    const closeModal = () => {
      store.dispatch('modals/closeModal', props.type);
    };

    watch(() => store.state.modals[props.type]?.open, (val) => {
      state.value.open = val;
    });

    return {
      te,
      entity,
      state,
      loading,
      deleteEntity,
      closeModal,
    };
  },
};
</script>
